<template>
  <div class="flex-container non-scrollable">
    <page-title value="Déclaration de facturation"/>
    <div class="non-scrollable">
      <DataTable ref="dt" :value="invoicesDeclarations" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading"
               :rowsPerPageOptions="constants.rowsPerPageOptions" selection-mode="single"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.portes')})"
               scrollable scroll-height="flex" @rowSelect="clickPorte">
        <template #header>
          <div class="flex justify-content-between">
            <div class="p-d-flex flex">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
              </span>
            </div>
            <div class="flex flex-row gap-2">
              <Calendar v-if="filtersSet" v-model="filterDate" view="month" date-format="MM (yy)" showIcon
                        @update:modelValue="filterDateBy"/>
            </div>
            <div>
              <!--  Nombre de paie validé sur nombre de porté actif -->
              <Button v-tooltip.top="'Nombre de déclaration sur le nombre de déclaration supposées'"
                      :label="missingInvoiceDeclarationsInfo ? `${missingInvoiceDeclarationsInfo?.countInvoiceDeclaration ?? 0 } / ${missingInvoiceDeclarationsInfo?.countSupposedInvoiceDeclaration ?? 0}` : ''"
                      :loading="missingInvoiceDeclarationsInfoLoading"/>
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
        </template>
        <template #loading>
          {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
        </template>
        <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable sortField="independent.last_name">
  <!--        <template #filter="{filterModel, filterCallback}">-->
  <!--          <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>-->
  <!--          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="lastnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">-->
  <!--            <template #option="slotProps">-->
  <!--              <div>-->
  <!--                <span>{{slotProps.option.value}}</span>-->
  <!--              </div>-->
  <!--            </template>-->
  <!--          </MultiSelect>-->
  <!--        </template>-->
        </Column>
        <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable sortField="independent.first_name">
  <!--        <template #filter="{filterModel, filterCallback}">-->
  <!--          <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>-->
  <!--          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="firstnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">-->
  <!--            <template #option="slotProps">-->
  <!--              <div>-->
  <!--                <span>{{slotProps.option.value}}</span>-->
  <!--              </div>-->
  <!--            </template>-->
  <!--          </MultiSelect>-->
  <!--        </template>-->
        </Column>
        <Column field="quantity" :header="$t('columnWorkedDaysOnWorkableDays')">
          <template #body="{data}">
            <span>{{ getServiceWorkedDays(data) }}</span>
          </template>
        </Column>
        <Column field="status" header="ETAT" sortable filterField="status" sortField="status" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            <span class="capitalize">{{getOptionLabel(constants.data.facturationOptions, data.status)}}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">Etat</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="constants.data.facturationOptions" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.label}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
  <!--      <Column field="reminder" header="RAPPEL" filterField="reminder" sortField="reminder" :showFilterMatchModes="false">-->
  <!--        <template #body="{data}">-->
  <!--          <Checkbox :binary="true" type="text" v-model="data.reminder" @change="checkReminder(data)" :disabled="data.reminder"/>-->
  <!--        </template>-->
  <!--      </Column>-->
        <Column field="month" :header="$t('date').toUpperCase()" sortable filterField="month" sortField="month" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            <span class="capitalize">{{getDateFormatted(new Date(data.year, data.month), {month: 'long'})}}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">Date</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="monthFilter" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span class="capitalize">{{slotProps.option.label.toLocaleDateString('fr-FR', {month: 'long' })}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="year" filterField="year" hidden/>
        <Column field="independent.status" :header="$t('status').toUpperCase()" sortable filterField="independent.status" sortField="independent.status" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            <Chip :class="'custom-chip-' + data.independent.status" :label="getOptionLabel(constants.data.independentOptions, data.independent.status)?.toUpperCase()"></Chip>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">Statut</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="constants.data.independentOptions" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <Chip :class="'custom-chip-' + slotProps.option.value" :label="slotProps.option.label"></Chip>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <div class="flex flex-column align-items-center gap-2">
              <Button label="RELANCE" class="p-button-rounded" size="small" @click="emailIndependent(data)" />
              <div v-if="data.last_notification_date !== null" class="flex flex-row gap-1">
                <span class="text-xs">{{ `Dernière le ` }}</span>
                <span class="text-xs font-bold">{{ `${new Date(data.last_notification_date).toLocaleDateString('fr-FR')}` }}</span>
              </div>
            </div>
          </template>
        </Column>
    </DataTable>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {mapState} from "vuex";
import {disablePComponentsOfCurrentPage, getOptionLabel, hasAccess} from "@/utils/Misc";
import {getDateFormatted} from "@/utils/Date";
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "TableauDeclareFacture",
  components: {PageTitle},
  data() {
    return {
      customers: null,
      selectedCustomers: null,

      filtersSet: false,
      filterDate: null,

      missingInvoiceDeclarationsInfoLoading: true,
      missingInvoiceDeclarationsInfo: null,

      filters: {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'month': {value: null, matchMode: FilterMatchMode.IN},
        'year': {value: null, matchMode: FilterMatchMode.IN},
        'independent.status': {value: null, matchMode: FilterMatchMode.IN},
      },
      loading: true,
      exportSheetLoading: false
    }
  },
  computed: {
    ...mapState({
      declarationsFacturation: state => state.declarationFacturation.declarationsFacturation,
      constants: state => state.constants
    }),
    invoicesDeclarations () {
      const baseArray = this.declarationsFacturation.filter(df => !df.disabled && !df.invoice)
      const finalArray = []
      baseArray.forEach((df) => {
        if (!finalArray.find((d) => d.independent.id === df.independent.id && d.month === df.month && d.year === df.year)) {
          finalArray.push(df)
        }
      })
      return finalArray
    },
    lastnamesFilter () {
      let array = this.paies.map((p) => p.independent.last_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    firstnamesFilter () {
      let array = this.paies.map((p) => p.independent.first_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    monthFilter () {
      let array = this.invoicesDeclarations.map((p) => { return {month: p.month, year: p.year}})
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item.month)) {
          filterArray.push({
            value: item.month,
            label: new Date(item.year, item.month)
          })
        }
      })
      return filterArray
    },
  },
  watch: {
    async filterDate (value) {
      this.missingInvoiceDeclarationsInfo = null
      this.missingInvoiceDeclarationsInfoLoading = true
      this.missingInvoiceDeclarationsInfo = await this.$store.dispatch('declarationFacturation/getMissingInvoiceDeclaration', { year: value.getFullYear(), month: value.getMonth() })
      this.missingInvoiceDeclarationsInfoLoading = false
    }
  },
  mounted() {
    this.loading = true
    this.$store.dispatch('declarationFacturation/getAllDeclarationFacturation').then(() => {
      this.initFilters()
      this.loading = false;
      this.$nextTick(() => {
        if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
      })
    })
  },
  methods: {
    getOptionLabel,
    getDateFormatted,
    initFilters () {
      this.filtersSet = false
      let filterDate = this.getInitialFilterDate()
      this.filterDate = filterDate?.value
      this.filters = {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'month': {value: filterDate?.monthValues, matchMode: FilterMatchMode.IN},
        'year': {value: filterDate?.yearValues, matchMode: FilterMatchMode.IN},
        'independent.status': {value: null, matchMode: FilterMatchMode.IN},
      }
      this.filtersSet = true
    },
    getInitialFilterDate () {
      const currentDate = new Date()
      if (currentDate.getDate() > 10) {
        return { monthValues: [currentDate.getMonth()], yearValues: [currentDate.getFullYear()], value: currentDate }
      } else {
        currentDate.setMonth(currentDate.getMonth() - 1)
        return { monthValues: [currentDate.getMonth()], yearValues: [currentDate.getFullYear()], value: currentDate }
      }
    },
    getServiceWorkedDays (invoiceDeclaration) {
      const dailyBase = invoiceDeclaration?.mission?.jsonCost?.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
          || invoiceDeclaration?.mission?.jsonCost?.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
      return`${invoiceDeclaration?.timesheet?.reduce((accumulator, currentValue) => accumulator + (currentValue?.total ? currentValue.total : 0), 0)}${dailyBase ? ` / ${invoiceDeclaration?.workableDays}` : ''} ${ dailyBase ? 'Jours' : 'Heures'}`
    },
    clickPorte (porte) {
      this.$router.push({ name: 'DeclareFactureAdminAdv', query: { idIndependent: porte.data.independent.id, year: porte.data.year, month: porte.data.month }})
    },
    filterDateBy (filterValue) {
      this.filters = {
        ...this.filters,
        'month': {value: !filterValue ? null : [filterValue.getMonth()], matchMode: FilterMatchMode.IN},
        'year': {value: !filterValue ? null : [filterValue.getFullYear()], matchMode: FilterMatchMode.IN},
      }
    },
    emailIndependent (invoiceDeclaration) {
      this.$confirm.require({
        header: 'Relance',
        message: 'Êtes-vous sûr de vouloir relancer ce porté pour cette déclaration de facturation',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.dispatch('declarationFacturation/notifyIndependent', {
            independentId: invoiceDeclaration.independent.id,
            invoiceDeclarationId: invoiceDeclaration.id
          })
          this.$confirm.close()
        },
        reject: () => {
          this.$confirm.close()
        }
      })
    }
  }
}
</script>


